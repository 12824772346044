<!-- añadir / eliminar claves Agencias Afectas  -->

<template>
  <div class="agen_M_claves">   
      <div>
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="$emit('onEvent', { accion:'cerrar' })">
            </base_Header>                  
          </div>


          <!-- Contenido -->
          <div class="contenedor" style="width:500px"> 
            <div class="columna">
              <v-btn 
                v-bind="$cfg.btra_cfg.standard"
                title="Guardar datos"
                @click="guardar()">
                  <v-icon>{{'mdi-content-save'}}</v-icon>
              </v-btn>
              
              <!-- <div>Introduce el código postal o parte del mismo<br>separado por comas</div> -->
              <v-textarea
                v-bind="$textarea"
                style="padding-top:10px"
                v-model="schema.ctrls.txt.value"
                :label="schema.ctrls.txt.label"
                rows="3"
                no-resize>
              </v-textarea>

            </div>
          </div>
        </v-sheet> 
      </div>
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
 
  export default {
    mixins: [mixinCtrls],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:Number, default: 0},
      accionRow: { type:Object, default: null},      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {          
            txt: { f:'txt', label:'Introduce la/s claves de producción separadas por comas', value:""}
          }
        },
        api:'',
        stName:'stMagen_M_claves',      
      };
    },


    created() {
      this.ini_data();
      this.ini_schemaCtrls();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = this.accion==1? 'AÑADIR' : 'ELIMINAR';        
      },


      //
      ini_schemaCtrls() {
      },


      //
      async guardar() {
        if (this.schema.ctrls.txt.value== "") {
          this.$root.$alert.open('Debe introducir una o varias claves de producción', 'error');
          return;
        }
        
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'claves',
          fnt:this.accion==1? 'set' : 'anular',
          age:this.accionRow.id,
          claves:this.schema.ctrls.txt.value,         
        }};
      
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });          
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Operación realizada correctamente', 'success');

        // actualizo lista de zonas y salgo
        this.$emit('updateClaves', { record:apiResult.r[0] });
        this.$emit('onEvent', { accion:'cerrar' });
      }


    }
  };
</script>
